import React from 'react';
import { graphql } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import BlockContent, {
  BlockContentProps,
} from '@sanity/block-content-to-react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import { Feature as IFeature, ImageWithAlt } from '../types';
import Quote from '../components/Quote';
import Gallery from '../components/shared/Gallery';
import Feature from '../components/Feature';

interface Fact {
  _key: string;
  amount: string;
  description: string;
  suffix: string;
}

interface Quote {
  __typename: 'SanityQuote';
  _key: string;
  content: string;
  author: string;
  link: string;
}

interface Gallery {
  __typename: 'SanityGallery';
  _key: string;
  images: Array<ImageWithAlt>;
}

interface Feature extends IFeature {
  __typename: 'SanityFeature';
  _key: string;
}

interface Props {
  data: {
    sanityService: {
      title: string;
      image: ImageWithAlt;
      facts: Array<Fact>;
      _rawDescription: unknown;
      pageSections: Array<Quote | Feature | Gallery>;
    };
  };
  path: string;
}

const serializers: BlockContentProps['serializers'] = {
  list: ({ children }) => <ul className="!mt-2 ml-7">{children}</ul>,
  listItem: ({ children }) => (
    <li>
      <strong className="pr-1">·</strong> {children}
    </li>
  ),
};

const Service: React.FC<Props> = ({ data: { sanityService }, path }) => {
  const featureCount = React.useRef(0);

  return (
    <Layout currentPath={path}>
      <SEO image={sanityService.image} title={sanityService.title} />

      <div className="space-y-28 sm:space-y-36">
        {/* Hero section */}
        <section className="mt-10 xl:container md:mt-16">
          <h1 className="px-4 text-3xl font-light tracking-tight text-gray-700 sm:text-4xl md:text-5xl lg:text-6xl xl:px-0 xl:text-7xl">
            {sanityService.title}
          </h1>

          <div className="relative mt-5">
            <GatsbyImage
              alt={sanityService.image.alt}
              className="max-h-[800px] object-cover"
              fluid={sanityService.image.asset.fluid}
            />
          </div>
        </section>

        {/* Insights & description */}
        <section className="container flex flex-col justify-around space-y-14 lg:flex-row lg:space-y-0">
          <div className="space-y-10 text-center text-blue-900 sm:flex sm:space-y-0 lg:block lg:w-4/12 lg:space-y-10 lg:text-left xl:w-3/12">
            {sanityService.facts.map((fact: Fact) => (
              <div key={fact._key}>
                <div className="text-5xl font-semibold lg:text-7xl">
                  {fact.amount}
                  <span className="font-bold text-blue-600">{fact.suffix}</span>
                </div>

                <p className="text-lg">{fact.description}</p>
              </div>
            ))}
          </div>

          {sanityService._rawDescription && (
            <BlockContent
              blocks={sanityService._rawDescription}
              className="space-y-6 text-lg leading-normal lg:w-1/2 lg:text-xl"
              renderContainerOnSingleChild
              serializers={serializers}
            />
          )}
        </section>

        {sanityService.pageSections.map((section) => {
          if (section.__typename === 'SanityQuote') {
            return (
              <Quote
                author={section.author}
                content={section.content}
                key={section._key}
                link={section.link}
              />
            );
          }

          if (section.__typename === 'SanityGallery') {
            return (
              <Gallery
                className="lg:container"
                images={section.images}
                key={section._key}
              />
            );
          }

          if (section.__typename === 'SanityFeature') {
            return (
              <Feature
                color={section.color}
                description={section.description}
                image={section.image}
                items={section.items}
                key={section._key}
                linkWithDescription={section.linkWithDescription}
                reverse={featureCount.current++ % 2 !== 0}
                title={section.title}
              />
            );
          }
        })}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query Service($slug: String!) {
    sanityService(slug: { current: { eq: $slug } }) {
      title
      image {
        alt
        asset {
          _id
          fluid {
            ...GatsbySanityImageFluid_withWebp
          }
        }
      }
      facts {
        _key
        amount
        suffix
        description
      }
      _rawDescription
      pageSections {
        __typename
        ... on SanityQuote {
          _key
          content
          author
          link
        }
        ... on SanityFeature {
          _key
          title
          items
          linkWithDescription: link {
            url
            description
          }
          description
          color
          image {
            alt
            asset {
              fluid {
                ...GatsbySanityImageFluid_withWebp
              }
            }
          }
        }
        ... on SanityGallery {
          _key
          images {
            alt
            asset {
              _id
              fluid {
                ...GatsbySanityImageFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;

export default Service;
